import React from 'react'
import '../src/footer.css';
function Footer() {
  return (
    <>
      {/* Footer Area */}
<footer id="footer" className="footer ">
 
  
 {/*/ End Footer Top */}
 {/* Copyright */}
 <div className="copyright">
   <div className="container">
     <div className="row">
       <div className="col-lg-4 col-md-12 col-12">
         <div className="copyright-content">
         <p>
                 <a href="/data"><i className="fa fa-caret-right" aria-hidden="true"/>Data protection</a>&nbsp;&nbsp;&nbsp;&nbsp;
                 {/* <a href="/services"><i className="fa fa-caret-right" aria-hidden="true" /> Services</a>
                 <a href="/solutions"><i className="fa fa-caret-right" aria-hidden="true" /> Solutions</a> */}
                 <a href="/cookie"><i className="fa fa-caret-right" aria-hidden="true" />Cookies</a>&nbsp;&nbsp;&nbsp;
                 <a href="/term"><i className="fa fa-caret-right" aria-hidden="true" />Terms & condition</a>	
               </p>
         </div>
       </div>
       <div className="col-lg-4 col-md-12 col-12">
         <div className="copyright-content">
           <p>Copyrights © 2024 Techsnurf Global Service (OPC) Private Limited</p>
         </div>
       </div>
       <div className="col-lg-4 col-md-12 col-12">
         {/* <div className="copyright-content"> */}
           <p> 
             <a href="https://www.facebook.com/techsnurf.gs.14"> <i class="fa fa-facebook-official" style={{fontSize:'20px',color:'white'}}></i></a>&nbsp;
             <a href="https://x.com/TechsnurfGs?s=09"> <i class="icofont-twitter"style={{fontSize:'20px',color:'white'}}></i></a>&nbsp;
             <a href="https://www.linkedin.com/company/techsnurfgsopclimited/posts/?feedView=all"> <i class="icofont-linkedin"style={{fontSize:'20px',color:'white'}}></i></a>&nbsp;
             <a href="https://www.instagram.com/techsnufgs/"> <i class="icofont-instagram"style={{fontSize:'20px',color:'white'}}></i></a>&nbsp;
             </p>
         {/* </div> */}
       </div>
     </div>
   </div>
 </div>
 {/*/ End Copyright */}
</footer>
{/*/ End Footer Area */}


    </>
  )
}

export default Footer
