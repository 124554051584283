import React from 'react'
import '../homestyle/homethree.css';
function Homethree() {
  return (
    <>
      {/* Start Feautes */}
  <section className="Feautes section">
    <div className="container" style={{marginBottom:'-60px'}}>
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2 style={{marginTop:'-70px'}}>Techsnurf Global Service</h2>
            <img src="img/section-img.png" alt="#" />
            <p>We Give You The Right Solutions In Cyber Security TechSnurf is a robust managed security service provider, with over a decade of experience, trusted by organizations from around the globe from India.

Global Services are focused on enhancing your cyber resilience and cyber defence readiness, irrespective of the industries and geographies you are operating on. It aims to align your cyber security strategies and technology landscape with the business priorities identified.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features">
            <div className="signle-icon">
            <i class="fa fa-building" aria-hidden="true"></i>
            </div>
            <h3>638</h3>
            <p>Companies We Helped</p>
          </div>
          {/* End Single features */}
        </div>
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features">
            <div className="signle-icon">
            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
            </div>
            <h3>28</h3>
            <p>Trainings Courses</p>
          </div>
          {/* End Single features */}
        </div>
        <div className="col-lg-4 col-12">
          {/* Start Single features */}
          <div className="single-features last">
            <div className="signle-icon">
            <i class="fa fa-users" aria-hidden="true"></i>
            </div>
            <h3>18</h3>
            <p>Strtegic Partners</p>
          </div>
          {/* End Single features */}
        </div>
      </div>
    </div>
  </section>
  {/*/ End Feautes */}
  
    </>
  )
}

export default Homethree
