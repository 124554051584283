import React from 'react'
import Solutionone from './Solutionone'
import Solutiontwo from './Solutiontwo'
// import Solutionthree from './Solutionthree'

function Solution() {
  return (
    <>
      <Solutionone/>
      <Solutiontwo/>
      {/* <Solutionthree/> */}
    </>
  )
}

export default Solution
