import React from 'react'
import '../homestyle/homefour.css';
function Homefour() {
  return (
    <>
      {/* Pricing Table */}
  <section className="pricing-table section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title">
            <h2>About Us</h2>
            <img src="img/section-img.png" alt="#" />
            <p>Techsnurf Global Services, established in 2015, is a leading cybersecurity consulting firm recognized for delivering top-tier information security services to industry leaders worldwide. Our commitment to excellence and innovative approach allows us to provide integrated security solutions, offering comprehensive managed security services and consultancy to safeguard businesses against evolving cyber threats.</p>
          </div>
        </div>
      </div>
      <div className="row">
        {/* Single Table */}
        <div className="col-lg-4 col-md-12 col-12">
          <div className="single-table">
            {/* Table Head */}
            <div className="table-head">
              <div className="icon">
                {/* <i className="icofont icofont-ui-cut" /> */}
              </div>
              <h4 className="title">Mission</h4>
             <p style={{wordSpacing:'-2px',textAlign:'justify'}}>At Techsnurf, Our Mission is to empower organizations with tailored cybersecurity solutions, 
  enabling them to defend against current threats and anticipate future challenges. We envision a secure online environment 
  that fosters growth, driven by innovation and collaboration with industry leaders and clients to deliver exceptional cybersecurity 
  services and consultancy.deliver unparalleled cyber security services and consultancy.</p>

            </div>
            {/* Table List */}
           
            {/* Table Bottom */}
          </div>
        </div>
        {/* End Single Table*/}
        {/* Single Table */}
        <div className="col-lg-4 col-md-12 col-12">
          <div className="single-table">
            {/* Table Head */}
            <div className="table-head">
              <div className="icon">
                {/* <i className="icofont icofont-tooth" /> */}
              </div>
              <h4 className="title">Vision</h4>
             <p style={{wordSpacing:'-2px',textAlign:'justify'}}>At Techsnurf, our vision is to establish a safer digital landscape where businesses thrive without fear of cyber attacks. We aspire to a world where cybersecurity is a fundamental pillar, driving sustainable growth and success, empowering organizations to focus on innovation and progress in a secure environment.We envision a world where cyber security is not just a necessity but a foundation for sustainable growth and success.</p>
            </div>
            {/* Table List */}
           
            {/* Table Bottom */}
          </div>
        </div>
        {/* End Single Table*/}
        {/* Single Table */}
        <div className="col-lg-4 col-md-12 col-12">
          <div className="single-table">
            {/* Table Head */}
            <div className="table-head">
              <div className="icon">
                {/* <i className="icofont-heart-beat" /> */}
              </div>
              <h4 className="title">Driven By</h4>
             
            </div>
            {/* Table List */}
            <p style={{wordSpacing:'-1px',textAlign:'justify'}}>At Techsnurf, we define and manage security risks to empower business growth, building effective defenses against advanced threats. Our approach redefines security operations, delivering seamless and cost-effective solutions. Through advisory services, transformation initiatives, and optimized operations, we ensure organizations can navigate challenges while maintaining a strong security posture.</p>
            {/* Table Bottom */}
          </div>
        </div>
        {/* End Single Table*/}
      </div>	
    </div>	
  </section>	
  {/*/ End Pricing Table */}
 
    </>
  )
}

export default Homefour
