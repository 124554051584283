
// import './App.css';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import Home from './components/homecompoents/Home';
import Services from './components/servicescomponents/Service';
import Solutions from './components/solutioncomponents/Solution';
import Cyberhire from './components/cyberhirecomponents/Cyberhire';
import Contact from './components/contactcomponents/Contact';
import Terms from './components/termscomponets/Term';

import Footer from "./Footer";
import Header from "./Header";
import Term from './components/termscomponets/Term';
import Cookie from './components/cookiecomponents/Cookie';
import Dataprotect from './components/dataproteccomponents/Dataprotect';
// import Home from "./HomeComponents/Home";


function App() {
  return (
    <div>
  
   <BrowserRouter>
   <Header/>
   <Routes>
    <Route path="/" element={<Home/>}/>
    <Route path="/services" element={<Services/>}/>
    <Route path="/solutions" element={<Solutions/>}/>
    <Route path="/cyberhire" element={<Cyberhire/>}/>
    <Route path="/contact" element={<Contact/>}/>
    <Route path="/term" element={<Term/>}/>
    <Route path="/cookie" element={<Cookie/>}/>
    <Route path="/data" element={<Dataprotect/>}/>

   </Routes>
   
   <Footer/>
   </BrowserRouter>
   </div>
  );
}

export default App;
