import React from 'react'

function Cookie() {
  return (
    <div>
       <div style={{marginTop:'50px'}}>
     {/* Start Feautes */}
<section className="Feautes section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h2>Cookies</h2>
          <img src="img/section-img.png" alt="#" />
          </div>
      </div>
    </div>
   
  </div>
  <div style={{marginLeft:'40px'}}>
  <p >Cookies are small text files placed on your device when you visit a website. They are generated by a web page server and can be used by that server whenever the user visits the site.</p> <br></br>
        <h5>At TechSnurfgs</h5>
        <br>
        </br>
        <p>Cookies help us to provide a seamless browsing experience by recognising you each time you visit the site and helping our team to understand what parts of the website you find most interesting and useful. The cookie settings on this site are set to ‘allow all’– but if you want, you can change your settings at any time. Please keep in mind that if you do, you may lose some functionality.</p>
        <br></br>
        <h5>Cookie Statement</h5>
        <br></br>
        <h5>At TechSnurfgs</h5>
        <br></br>
        <p>You are at www.TechSnurfgs.com, the website of TechSnurfgs India Private Limited hereinafter referred to as ‘TechSnurfgs or “we”.Our website (www.TechSnurfgs.com) and its subdomains use cookies. Cookies are small text files that will be stored in your device (computer, tablet or mobile phone) via your browser primarily to enhance the convenience of using the site, to enable certain functions and to make visiting our website attractive. Third parties can also place cookies and similar techniques to monitor your internet behaviour and show your personalized advertisements outside our website. The information in the cookies relates to your device, your browser and to the way you flip through our content and is not linked to your name, address or e-mail address. To the extent personal information may be collected using cookies, such information will only be processed in accordance to our privacy statement.</p>
         <br></br>
         <h5>Which Cookies Do We Use And Why?</h5>
         <br></br>
        <h5>At TechSnurfgs</h5>
        <br></br>
        <p>Cookies can be placed by directly by our website or by third parties with whom we cooperate to help us diagnose technical problems, analyse the traffic to our website, analyse trends, observe and measure how our visitors engage with our website and identify preferences.</p>
        <br></br>
        <h5>Default Cookies</h5>
        <br></br>
        <h5>At TechSnurfgs</h5>
        <br></br>
        <p>These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services, you have asked for, like logging in, cannot be provided. These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services, you have asked for, like logging in, cannot be provided. Our website uses Google Analytics, provided by Google, Inc. and Optimizely, provided by Optimizely inc. These cookies are essential to provide you a seamless browser experience. They provide us with information on how you use the website and allow us to test various parts of the website.</p>
        
        <br></br>
        <h5>Functional Cookies</h5>
        <br></br>
        <p>These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third-party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.</p>
        <br></br>
        <h5>Performance Cookies</h5>
        <br></br>
        <p>These cookies collect information about how visitors use a website, for instance, which pages visitors go to most often, and if they get error messages from web pages. Only TechSnurfgs has access to the data collected via these cookies and we do not make these data available to third parties.</p>
        <br></br>
        <h5>Advertising Or Targeting Cookies</h5>
        <br></br>
        <p>These cookies are used to deliver adverts more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organizations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organization including social media services that we have added to the site to enable you to share our content.</p>
        <br></br>
        <h5>Change Your Preference Regarding Cookies</h5>
        <br></br>
        <p>You can prevent your browser to store cookie and/or delete the cookies from your device at any time via the browser settings. Please consult the ‘Help’ section on your internet browser for instructions on this.If you choose to not accept cookies, you should be aware that you may no longer be able to make use of all possibilities and functionalities of our website, and that certain parts of the website may also no longer be visible or accessible. To opt-out from Google Analytics cookies at any time, you can also install the Google Analytics opt-out browser add-on. To opt-out from Optimizely, you can visit their opt-out page. Your can also opt-out from Hotjar cookies at any time by visiting Hotjar opt-out page and clicking 'Disable Hotjar'. Storage period. To opt-out from Google Analytics cookies at any time, you can also install the Google Analytics opt-out browser add-on. To opt-out from Optimizely, you can visit their opt-out page. Your can also opt-out from Hotjar cookies at any time by visiting Hotjar opt-out page and clicking 'Disable Hotjar'. Storage period. Changes to the cookies statement We may update the Cookies Statement from time to time. You can see the date on which the last change was made below in this statement. We advise you to review our Cookies Statement on a regular basis so that you are aware of any changes. This statement was updated on: 01 June 2020 For more information please contact us. Our consultants will provide you adequate details as per your needs.</p>
        </div>
</section>
{/*/ End Feautes */}


    </div>
    </div>
  )
}

export default Cookie
