import React from 'react';
import '../solutionstyle/solutiontwo.css';

function Solutiontwo() {
  return (
    <>
     {/* Start service */}
<section className="services section">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title">
          <h2>Solution</h2>
          <img src="img/section-img.png" alt="#" />
          
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-shield" aria-hidden="true"></i>
          <h4><a href="#">Network Secuirty</a></h4>
          <p>Network security refers to the practice of protecting computer networks and data
          from unauthorized access, breaches, and cyber threats.</p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-database" aria-hidden="true"></i>
          <h4><a href="#">Data Security</a></h4>
          <p>Data security encompasses the practices, technologies, and policies implemented to protect sensitive data from unauthorized access, disclosure, alteration, or destruction.</p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-bars" aria-hidden="true"></i>
          <h4><a href="#">End Point Secuirty</a></h4>
          <p>Endpoint security refers to the protection of individual devices, such as laptops, desktops, smartphones, tablets, and servers, from cybersecurity threats.</p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-anchor" aria-hidden="true"></i>
          <h4><a href="#">Technology Integration</a></h4>
          <p>Technology integration refers to the incorporation of technology tools, resources, and systems into various aspects of education, business, healthcare, or any other field to enhance productivity, efficiency, and effectiveness.
          </p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-cloud" aria-hidden="true"></i>
          <h4><a href="#">Cloud Security</a></h4>
          <p>cloud security refers to the set of policies, technologies, and practices designed to protect data, applications, and infrastructure in cloud computing environments. 
          </p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-lock" aria-hidden="true"></i>
          <h4><a href="#">Managed Secuirty Service</a></h4>
          <p>A Managed Security Service (MSS) is a comprehensive approach to managing an organization's security needs, typically outsourced to a third-party provider known as a Managed Security Service Provider (MSSP).</p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-cog" aria-hidden="true"></i>
          <h4><a href="#">Application Secuirty</a></h4>
          <p>Application security refers to the measures and practices implemented to protect software applications from security threats, vulnerabilities, and attacks throughout the software development lifecycle (SDLC). 
          </p>	
        </div>
        {/* End Single Service */}
      </div>
      <div className="col-lg-4 col-md-6 col-12">
        {/* Start Single Service */}
        <div className="single-service">
        <i class="fa fa-life-ring" aria-hidden="true"></i>
          <h4><a href="#">Security Operation center</a></h4>
          <p>A Security Operations Center (SOC) is a centralized facility or team responsible for monitoring, detecting, analyzing, and responding to cybersecurity threats and incidents within an organization's IT infrastructure.
          </p>	
        </div>
        {/* End Single Service */}
      </div>
      
    </div>
  </div>
</section>
{/*/ End service */}


    </>
  );
}

export default Solutiontwo;
