import React from 'react';
import '../servicestyle/servicetwo.css';
function Servicetwo() {
  const services = [
    {
      icon: "fa fa-cloud",
      title: "Cloud Transformation",
      description: "Cloud transformation refers to the process of migrating and transforming an organization's IT infrastructure, applications, and services to cloud-based environments. Cloud transformation enables businesses to modernize their IT infrastructure."
    },
    {
      icon: "fa fa-connectdevelop",
      title: "Network Security",
      description: "Protecting computer systems, networks, and data from unauthorized access, cyberattacks, and other security breaches. Network security encompasses a variety of tools and techniques to ensure that data is transmitted securely."
    },
    {
      icon: "fa fa-database",
      title: "Data Center Modernization",
      description: "Data center modernization involves upgrading and optimizing the infrastructure, processes, and technologies within a data center to improve efficiency, performance, scalability, and security. It supports modern workloads and performance."
    },
    {
      icon: "fa fa-exchange",
      title: "Network Transformation",
      description: "Network transformation refers to the comprehensive overhaul of an organization's network infrastructure, architecture, and operations to adapt to evolving business needs, technological advancements, and industry trends."
    },
    {
      icon: "fa fa-briefcase",
      title: "Digital Workplace",
      description: "It encompasses the digital tools, platforms, and practices that enable employees to work efficiently, collaborate effectively, and access information from anywhere, at any time. It enhances productivity by providing seamless access."
    },
    {
      icon: "fa fa-life-ring",
      title: "IT Managed Service",
      description: "IT Managed Services refers to the outsourcing of various IT functions and responsibilities to a third-party service provider, often referred to as a Managed Service Provider (MSP). These services typically include monitoring, maintenance, and cybersecurity."
    }
  ];

  return (
    <>
      {/* Start service */}
      <section className="services section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>Services</h2>
                <img src="img/section-img.png" alt="#" />
                {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit praesent aliquet. pretiumts</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((service, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                {/* Start Single Service */}
                <div className="single-service">
                  <i className={service.icon} aria-hidden="true"></i>
                  <h4><a href="#">{service.title}</a></h4>
                  <p>{service.description}</p>
                </div>
                {/* End Single Service */}
              </div>
            ))}
          </div>
        </div>
      </section>
      {/*/ End service */}
    </>
  );
}

export default Servicetwo;
