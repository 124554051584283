import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import '../cyberhirestyle/cyberhire.css';

function Cyberhire() {
    return (
        <>
            <div>
                {/* Slider Area */}
                <section className="slider">
                    <div className="hero-slider4">
                        <div className="single-slider" style={{ backgroundImage: 'url("img/talentAcquisition.jpg")' }}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="text">
                                            <h3>
                                                A Juggernaut In Digital Evolution & Cybersecurity Prowess
                                            </h3>
                                            <p style={{fontSize:'15px',color:'#757575',fontWeight:700}}>
                                                Accelerate Your Secure Business Transformation Journey With TechSnurfgs
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /End Slider Area */}

                {/* Leadership Section */}
                <div className="leadership-section">
                    <div className="row">
                        <div className="col">
                            <img src="img/blog1.jpg" alt="Leadership" className="leadership-image" />
                        </div>
                        <div className="col">
                            <p>
                                At TechsnurfGS, we understand that exceptional leadership is essential for innovation and success in today’s digital landscape. Our Leadership Recruitment service identifies and attracts visionary leaders who drive growth and foster collaboration. Using advanced assessment tools and extensive networks, we connect you with candidates who align with your values.
                            </p>
                            <p>
                                In an era where cybersecurity is critical, our Cyber Hire initiative sources top-tier professionals to safeguard your digital assets. Partner with TechsnurfGS to build a strong leadership pipeline and enhance your cybersecurity defenses. Together, we can turn challenges into opportunities for growth. Contact us today to explore how we can support your recruitment needs!
                            </p>
                            <Link to="/contact">
                                <button className="contact-button">
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* End Leadership Section */}
            </div>
        </>
    );
}

export default Cyberhire;
