import React from 'react'

function Dataprotect() {
  return (
    <div style={{marginTop:'50px'}}>
    {/* Start Feautes */}
<section className="Feautes section">
 <div className="container">
   <div className="row">
     <div className="col-lg-12">
       <div className="section-title">
         <h2>
         Data protection</h2>
         <img src="img/section-img.png" alt="#" />
         </div>
     </div>
   </div>
  
 </div>
 <div style={{marginLeft:'40px'}}>
 <p >Terms And Conditions
         The following terms and conditions are deemed to have been accepted by the User on usage of the website www.Techsnurfgs.com. The terms "You" and "User" refer to all individuals and/or entities accessing this site for any reason</p>
         <br></br>
       <h5>General disclaimer</h5>
       <br>
       </br>
       <p>Every effort has been made to ensure the accuracy of the information. The site is designed, updated and maintained by www.Techsnurfgs.com. The information contained in this Web site is intended, solely to provide general information for the personal use of the reader, who accepts full responsibility for its use and does not represent or endorse the accuracy or reliability of any information, including content or advertisements contained on, distributed through, or linked, downloaded or accessed from any of the services contained on this web site. The web site may contain inaccuracies, typographical and clerical errors, though efforts had been made to ensure accuracy. But www.Techsnurfgs.com reserves the right to change, alter, and delete any of the representations, clauses in this website without prior notice or information.</p>
       <br></br>
       <h5>Nowarranties</h5>
       <br></br>
       <p>www.Techsnurfgs.com does not make any warranties, express or implied, including, without limitation, those of merchantability, non-infringement and fitness for a particular purpose, and that the website will operate error free or that the website and its server are free of computer viruses or other harmful mechanisms with respect to any information, data, statements or products made available on the Site and is on a “as is” available basis only.</p>
       <br></br>
       <h5>Indemnity</h5>
       <br></br>
       <p>Users agree to indemnify and hold www.Techsnurfgs.com and its subsidiaries, affiliates, officers, agents, co-branders or other partners and employees, harmless from any claim or demand, including reasonable attorneys' fees made by any third party due to the content you submit, post or transmit through the Service, your use of the Service, your connection to the Service, your violation of the Terms and Conditions, or your violation of any rights of another user and any third party claims</p>
       <br></br>
       <h5>No liability</h5>
       <br></br>
       <p>In no event shall www.Techsnurfgs.com be liable for any direct, indirect, incidental, punitive, or consequential damages of any kind whatsoever with respect to the service, contents as found in this website. You further acknowledge and agree that www.Techsnurfgs.com, under no circumstances shall be liable for any direct, indirect, incidental, special, exemplary or consequential damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses whatsoever.</p>
       <br></br>
       <h5>Intellectual property rights</h5>
       <br></br>
       <p>All Intellectual property rights are vested solely with www.Techsnurfgs.com. No part of the webpage/service shall be reproduced or transmitted or stored in any other web site, nor may any of its information or part thereof be disseminated in any electronic or non-electronic form, nor included in any public or private electronic retrieval system or service without obtaining prior written permission from www.Techsnurfgs.com. Any infringement in this regard shall be adequately compensated by the User.</p>
       <br></br>
       <h5>Other terms and conditions</h5>
       <br></br>
       <p>By using this web site you are indicating your acceptance to abide by the Terms and Conditions which can be updated or modified at any time by www.Techsnurfgs.com. www.Techsnurfgs.com do not disclose any personal information unless specifically requested by the user or required to do so by the law or in good faith that such disclosure is reasonably necessary to: (a) Comply with legal processes (b) Enforce the Terms and Conditions (c) Respond to claims that any content violates the rights of third parties or (d) Protect the rights, property or personal safety of www.Techsnurfgs.com , its users and the public. www.Techsnurfgs.com does not take any responsibility for the acts/omissions on the part of the user(s)/visitor(s)/customer(s) of the site. www.Techsnurfgs.com shall not be obliged to resolve or mediate any dispute or difference, which may arise between users/visitors Through this website you may be re - directed to other sites for various purposes and links. www.Techsnurfgs.com shall not be liable for any of such sites and their related links. www.Techsnurfgs.com shall not be liable if any such links shall directly or indirectly lead to any slander, prohibited sites or obscene sites. This site and the intended activities are for the promotion and welfare of the society and do not contribute to any other unauthorized activities.The website and its users are subject to Chennai jurisdiction</p>
       </div>
</section>
{/*/ End Feautes */}


   </div>
  )
}

export default Dataprotect
