import React from 'react'
import '../homestyle/hometwo.css';
function Hometwo() {
  return (
    <>
      {/* Start Schedule Area */}
  <section className="schedule">
    <div className="container">
      <div className="schedule-inner">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 ">
            {/* single-schedule */}
            <div className="single-schedule first">
              <div className="inner">
                <div className="icon">
                  {/* <i className="fa fa-ambulance" /> */}
                </div>
                <div className="single-content">
                  {/* <span>Lorem Amet</span> */}
                  <h4 style={{marginLeft:'90px'}}>Security <br></br>Consultant</h4>
                  {/* <p>Lorem ipsum sit amet consectetur adipiscing elit. Vivamus et erat in lacus convallis sodales.</p> */}
                  {/* <a href="#">LEARN MORE<i className="fa fa-long-arrow-right" /></a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            {/* single-schedule */}
            <div className="single-schedule middle">
              <div className="inner">
                <div className="icon">
                  {/* <i className="icofont-prescription" /> */}
                </div>
                <div className="single-content">
                  {/* <span>Fusce Porttitor</span> */}
                  <h4 style={{marginLeft:'30px'}}>Technology Integration & Implementation</h4>
                  {/* <p>Lorem ipsum sit amet consectetur adipiscing elit. Vivamus et erat in lacus convallis sodales.</p>
                  <a href="#">LEARN MORE<i className="fa fa-long-arrow-right" /></a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-12">
            {/* single-schedule */}
            <div className="single-schedule last">
              <div className="inner">
                <div className="icon">
                  {/* <i className="icofont-ui-clock" /> */}
                </div>
                <div className="single-content">
                  {/* <span>Donec luctus</span> */}
                  <h4 style={{marginLeft:'50px'}}>Managed<br></br> Security Services </h4>
                 
                  {/* <a href="#">LEARN MORE<i className="fa fa-long-arrow-right" /></a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/End Start schedule Area */}
    </>
  )
}

export default Hometwo
