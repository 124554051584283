import React from 'react'
import Serviceone from './Serviceone'
import Servicetwo from './Servicetwo'

function Service() {
  return (
    <>
      <Serviceone/>
      <Servicetwo/>
    </>
  )
}

export default Service
