import React from 'react';
import '../homestyle/homeone.css';

function Homeone() {
  return (
    <>
      <div>
        {/* Slider Area */}
        <section className="slider">
          <div className="hero-slider1">
            {/* Start Single Slider */}
            <div className="single-slider" style={{ backgroundImage: 'url("img/business-presentation_1098-17033.jpg")'}}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="text">
                      <h3>A Global Leader In Digital Transformation & Cybersecurity</h3>
                      <p style={{ fontSize: '15px', color: '#757575', fontWeight: 700 }}>
                        Accelerate Your Secure Business Transformation Journey With TechSnurfgs
                      </p>
                      <div className="button">
                        {/* Add button elements here if needed */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Single Slider */}
          </div>
        </section>
        {/* / End Slider Area */}
      </div>
    </>
  );
}

export default Homeone;
