import React from 'react'
import Homeone from './Homeone'
import Hometwo from './Hometwo'
import Homethree from './Homethree'
import Homefour from './Homefour'

function Home() {
  return (
    <>
      <Homeone/>
      <Hometwo/>
      <Homethree/>
      <Homefour/>
    </>
  )
}

export default Home
