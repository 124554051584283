import React from 'react'
import  { useState } from 'react';
import '../contactstyle/contact.css';
import emailjs from 'emailjs-com';

function Contact() {
  const sliderData = [
    {
      backgroundImage: 'url("img/sidebar-contact.jpg")',
      title: 'A Juggernaut In',
      span1: 'Digital Evolution',
      span2: 'Cybersecurity prowess',
      description: 'Swiftly Navigate Your Secure Business Transformation Expedition With TechSnurfgs'
    },
  ];
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    website: 'Techsnurf',
    type: 'Contact',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetForm = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(false); 
    setError(false);  

    emailjs.send(
      'service_habilesec_sites', 
      'template_tm3vyum', 
      formData,
      'aWuqb54I4f_3Y6bse'
    )
      .then((response) => {
        console.log('Email sent successfully', response.status, response.text);
        setSuccess(true);  // Set success to true on successful response
        resetForm();
      })
      .catch((error) => {
        console.error('Failed to send email', error);
        setError(true);  
      })
      .finally(() => {
        setLoading(false); 
      });
  };
  return (
    <>
      
       {/* Slider Area */}
      <section className="slider">
        <div className="hero-slider5">
          {/* Map over the sliderData array to dynamically create each slider */}
          {sliderData.map((slide, index) => (
            <div key={index} className="single-slider" style={{ backgroundImage: slide.backgroundImage }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="text">
                      <h3>{slide.title} <span>{slide.span1}</span> & <span>{slide.span2}</span></h3>
                      <p  style={{fontSize:'15px',color:'#757575',fontWeight:700}}>{slide.description}</p>
                      <div className="button">
                        {/* Add buttons or other interactive elements here if needed */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      {/*/ End Slider Area */}
     
      <section className="contact-us contact_section">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact-us-form">
                  <h2>Contact With Us</h2>
                  <p>If you have any questions please feel free to contact us.</p>
                  
                  {/* Form */}
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            maxLength="30"
                            required
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            maxLength="60"
                            required
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            pattern="[0-9]{10}"
                            maxLength="20"
                            required
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={formData.subject}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            placeholder="Your Message"
                            required
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group login-btn">
                          <button className="btn" type="submit" disabled={loading}>Send</button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                  {/* Success/Failure Message */}
                  <div className="status-indicator">
                    {loading && <div className="spinner"></div>}
                    {success && !loading && (
                      <div className="success-message">
                        <p>✔ Email Sent Successfully</p>
                      </div>
                    )}
                    {error && !loading && (
                      <div className="error-message">
                        <p>✘ Failed to send email. Please try again.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  )
}

export default Contact
