import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
 import '../src/header.css'; 

function Header() {
    // State to keep track of the currently active link
    const [activeLink, setActiveLink] = useState('/'); // Default to Home

    // Function to handle setting the active link
    const handleSetActive = (link) => {
        setActiveLink(link);
    };

    return (
        <>
            {/* Header Area */}
            <header className="header">
                {/* Header Inner */}
                <div className="header-inner">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-12">
                                    {/* Start Logo */}
                                    <div className="logo">
                                        <Link to="/">
                                            <img src="img/logo.png" alt="Logo" />
                                        </Link>
                                    </div>
                                    {/* End Logo */}
                                    {/* Mobile Nav */}
                                    <div className="mobile-nav" />
                                    {/* End Mobile Nav */}
                                </div>
                                <div className="col-lg-7 col-md-9 col-12">
                                    {/* Main Menu */}
                                    <div className="main-menu">
                                        <nav className="navigation">
                                            <ul className="nav menu">
                                                <li className={activeLink === '/' ? 'active' : ''}>
                                                    <Link to="/" onClick={() => handleSetActive('/')}>Home</Link>
                                                </li>
                                                <li className={activeLink === '/services' ? 'active' : ''}>
                                                    <Link to="/services" onClick={() => handleSetActive('/services')}>Services</Link>
                                                </li>
                                                <li className={activeLink === '/solutions' ? 'active' : ''}>
                                                    <Link to="/solutions" onClick={() => handleSetActive('/solutions')}>Solutions</Link>
                                                </li>
                                                <li className={activeLink === '/cyberhire' ? 'active' : ''}>
                                                    <Link to="/cyberhire" onClick={() => handleSetActive('/cyberhire')}>Talent Acquisition</Link>
                                                </li>
                                                <li className={activeLink === '/contact' ? 'active' : ''}>
                                                    <Link to="/contact" onClick={() => handleSetActive('/contact')}>Contact Us</Link>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                    {/* End Main Menu */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Header Inner */}
            </header>
            {/* End Header Area */}
        </>
    );
}

export default Header;
